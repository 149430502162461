import React from 'react'

export const Finch = ({ color, size }) => {
  return (
    <svg fill={color} height={size} width={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
    viewBox="0 0 424.987 424.987" xmlSpace="preserve">
      <g>
        <path d="M351.945,78.127c0-15.715-12.785-28.5-28.5-28.5s-28.5,12.785-28.5,28.5s12.785,28.5,28.5,28.5
          S351.945,93.842,351.945,78.127z M323.445,86.627c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
          S328.132,86.627,323.445,86.627z"/>
        <path d="M418.793,44.984c-1.861-5.2-7.586-7.905-12.786-6.042l-10.469,3.749C382.314,17.354,355.761,0,325.433,0
          c-0.211,0-0.424,0.001-0.635,0.002c-28.26,0.232-54.416,15.711-68.26,40.398c-15.471,27.58-32.914,51.936-51.846,72.389
          C148.867,173.092,68.993,257.436,66.238,260.344l-54.92,55.768c-6.843,6.942-7.62,17.3-1.89,25.187
          c5.731,7.886,15.822,10.349,24.537,5.985c35.455-17.72,77.226-19.256,114.606-4.212c18.526,7.456,38.012,11.621,58.021,12.433
          l20.874,62.641c1.396,4.191,5.299,6.841,9.486,6.841c1.047,0,2.114-0.166,3.162-0.516c5.24-1.746,8.072-7.409,6.326-12.648
          l-18.893-56.697c5.313-0.412,10.579-1.064,15.785-1.949l21.651,64.969c1.396,4.19,5.299,6.841,9.486,6.841
          c1.048,0,2.113-0.166,3.162-0.516c5.24-1.746,8.072-7.409,6.326-12.649l-21.048-63.16c27.994-8.187,53.671-23.389,74.897-44.743
          c33.171-33.371,51.283-77.511,51.001-124.292c-0.102-15.604-2.251-31.018-6.398-45.91c14.413-15.004,22.375-35.102,22.026-56.085
          c-0.094-5.551-0.779-10.96-1.973-16.178l10.285-3.684C417.95,55.908,420.655,50.184,418.793,44.984z M384.442,77.968
          c0.287,17.303-6.992,33.803-19.973,45.27c-2.968,2.622-4.108,6.749-2.909,10.523c4.71,14.82,7.149,30.295,7.251,45.991
          c0.25,41.412-15.797,80.502-45.186,110.068c-7.557,7.603-15.753,14.32-24.459,20.115c-4.381-13.661-6.606-27.898-6.606-42.473
          c0-30.697,9.801-59.795,28.344-84.149c3.346-4.394,2.496-10.668-1.898-14.014c-4.396-3.347-10.67-2.495-14.014,1.898
          c-21.217,27.866-32.432,61.154-32.432,96.265c0,18.106,3.003,35.765,8.919,52.624c-20.836,10.187-43.83,15.576-67.629,15.576
          c-19.987,0-39.438-3.749-57.812-11.144c-19.451-7.828-39.949-11.72-60.345-11.72c-23.459,0-46.781,5.152-68.194,15.386
          l53.056-53.876c0.045-0.046,0.09-0.092,0.135-0.139c0.813-0.859,82.074-86.649,138.679-147.794
          c19.999-21.606,38.373-47.24,54.612-76.192c10.344-18.444,29.878-30.009,50.979-30.182c21.891-0.176,41.204,11.756,51.532,29.511
          l-2.987,1.07c-5.199,1.862-7.904,7.587-6.042,12.786c1.463,4.085,5.311,6.631,9.414,6.631c1.119,0,2.258-0.189,3.372-0.588
          l3.204-1.148C384.039,71.42,384.385,74.659,384.442,77.968z"/>
        <path d="M257.13,174.243c-0.009,0-0.017,0-0.025,0c-5.511,0-9.985,4.461-9.999,9.976c-0.001,0.365-0.005,0.729-0.01,1.086
          c-0.339,22.138-9.196,43.061-24.938,58.914c-15.743,15.853-36.603,24.853-58.735,25.342c-8.946,0.196-17.647-0.942-26.018-3.397
          l83.582-92.036c3.713-4.089,3.408-10.413-0.681-14.126c-4.088-3.713-10.413-3.409-14.126,0.68l-93.384,102.829
          c-2.123,2.339-3.018,5.543-2.41,8.644c0.606,3.1,2.643,5.732,5.491,7.097c14.313,6.86,29.625,10.331,45.553,10.331
          c0.81,0,1.621-0.009,2.434-0.027c27.334-0.604,53.076-11.7,72.485-31.245c19.407-19.543,30.326-45.362,30.745-72.709
          c0.007-0.446,0.011-0.891,0.012-1.335C267.119,178.744,262.652,174.256,257.13,174.243z"/>
        <path d="M336.89,139.683c-3.255,2.417-6.46,4.992-9.525,7.654c-4.17,3.621-4.615,9.937-0.993,14.107
          c1.977,2.277,4.758,3.443,7.554,3.443c2.325,0,4.66-0.806,6.553-2.45c2.683-2.33,5.487-4.583,8.334-6.697
          c4.435-3.292,5.36-9.556,2.068-13.99C347.587,137.317,341.324,136.391,336.89,139.683z"/>
      </g>
    </svg>
  )
}

export const Raven = ({ color, size }) => {
  return (
    <svg fill={color} height={size} width={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 360.416 360.416" xmlSpace="preserve">
      <g>
        <path d="M358.952,150.509c2.597-11.582,1.212-22.53-0.127-33.119c-1.144-9.042-2.223-17.581-0.591-25.51
          c3.869-18.796-8.128-39.427-19.013-55.301c-12.143-17.708-25.283-20.22-42.522-21.836c-0.932-0.087-1.884-0.132-2.829-0.132
          c-11.568,0-21.231,6.474-26.423,9.952c-1.347,0.902-2.739,1.835-3.147,1.917c-0.646,0.129-2.158,0.149-4.071,0.175
          c-5.138,0.068-13.736,0.183-23.974,2.376c-9.976,2.138-17.97,7.219-17.821,11.327c0.056,1.556,1.194,3.561,6.298,4.274
          c10.694,1.495,20.149,4.392,27.746,6.719c1.502,0.46,2.911,0.892,4.218,1.277c8.22,2.426,15.813,5.741,18.413,19.486
          c1.809,9.558-14.625,20.525-29.123,30.202c-6.014,4.013-11.693,7.804-16.268,11.521c-15.954,12.963-46.374,39.235-56.119,48.467
          c-9.237,8.751-86.918,62.013-107.799,72.199c-21.338,10.409-42.134,26.726-47.345,37.147c-1.168,2.336-1.418,3.816-0.812,4.797
          c0.265,0.428,0.812,0.938,1.915,0.938c1.313,0,3.111-0.757,4.662-1.491c0.002,0.188,0.037,0.381,0.119,0.578
          c0.463,1.119,1.955,1.262,3.203,1.262c3.411,0,10.521-1.401,19.192-3.771c-3.114,2.302-9.293,6.265-14.915,9.871
          C9.595,298.094-0.274,305.019,0.006,308.368c0.154,1.855,1.38,4.066,6.345,4.066c3.048,0,6.762-0.783,9.747-1.412
          c1.549-0.326,2.891-0.609,3.818-0.717c-0.338,0.709-1.205,1.928-1.82,2.794c-1.992,2.802-4.052,5.7-2.585,7.534
          c0.44,0.551,1.189,0.819,2.287,0.819c3.285,0,10.301-2.437,17.086-4.794c4.844-1.683,9.419-3.272,12.184-3.825
          c4.222-0.844,24.688-11.443,44.479-21.693c14.766-7.647,31.502-16.314,33.303-16.512c3.507,0,31.84-2.067,49.711-7.174
          c3.983-1.138,8.238-1.715,12.647-1.715c10.719,0,21.066,3.333,29.931,6.643c-0.055,2.158-0.109,4.802-0.165,8.048
          c-0.151,8.905-0.218,18.128-0.196,20.565c0.029,3.404,6.457,9.411,15.534,17.525c3.734,3.338,8.317,7.436,9.159,8.91
          c-1.521,0.946-3.853,0.974-6.745,1.009c-5.052,0.061-11.97,0.144-19.146,5.616c-4.179,3.187-6.942,7.744-7.569,9.963
          c-0.059,0.205-0.234,0.828,0.152,1.339c0.215,0.283,0.545,0.446,0.906,0.446c0.301,0,0.604-0.112,0.93-0.342l0.737-0.527
          c2.341-1.677,7.822-5.605,10.766-6.725c3.979-1.514,6.902-2.131,10.092-2.131c4.188,0,9.138,1.076,16.806,3.063
          c4.696,1.216,8.705,1.808,12.256,1.808c4.619,0,7.973-0.978,11.523-2.013c4.131-1.204,8.401-2.449,15.383-2.449
          c1.297,0,2.665,0.044,4.067,0.132c7.649,0.479,14.502,4.462,17.796,6.376c1.418,0.824,1.847,1.073,2.311,1.073l0.706-0.028
          l0.265-0.59c0.347-0.771-0.089-1.261-2.182-3.619c-3.516-3.959-6.806-6.381-9.986-7.947c1.944-0.378,3.739-0.896,5.584-1.434
          c4.131-1.204,8.401-2.449,15.382-2.449c1.297,0,2.665,0.044,4.067,0.132c7.649,0.479,14.503,4.462,17.796,6.375
          c1.419,0.825,1.848,1.073,2.312,1.073l0.706-0.028l0.265-0.59c0.347-0.771-0.089-1.261-2.182-3.619
          c-7.444-8.383-13.889-9.927-20.382-10.875c-2.55-0.371-4.478-1.228-4.688-2.082c-0.173-0.699,0.774-1.882,2.534-3.164
          c3.122-2.274,6.262-3.427,9.333-3.427c5.441,0,8.826,3.572,9.194,4.93c0.166,0.616,0.653,0.834,1.021,0.834
          c0.375,0,0.87-0.228,1.03-0.868c0.301-1.196-0.06-6.437-4.487-8.808c-2.211-1.185-5.633-1.837-9.636-1.837
          c-9.456,0-19.744,3.326-28.221,9.011c-1.689-0.342-3.622-0.526-5.722-0.526c-0.583,0-1.17,0.018-1.758,0.043
          c-7.241-5.788-19.983-19.26-20.717-23.842c-0.483-3.021-0.765-12.566-0.765-21.797c0-0.035,0-0.068,0-0.103
          c6-2.984,12.091-6.5,19.155-10.656C325.72,237.381,354.159,171.891,358.952,150.509z M250.816,278.882
          c-0.079,6.328-0.111,11.825-0.095,13.628c0.03,3.403,6.457,9.41,15.533,17.524c3.53,3.155,7.813,6.985,8.984,8.641
          c-0.794,0.338-1.582,0.693-2.362,1.069c-1.208,0.168-2.619,0.19-4.206,0.209c-3.271,0.04-7.325,0.098-11.724,1.612
          c-7.352-6.351-18.116-18.122-18.793-22.343c-0.366-2.289-0.5-8.327-0.576-15.135C240.497,283.086,244.846,281.491,250.816,278.882z
          "/>
      </g>
      </svg>
  )
}

export const Flamingo = ({ color, size }) => {
  return (
    <svg fill={color} height={size} width={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 511.999 511.999" xmlSpace="preserve">
      <g>
        <g>
          <path d="M383.166,134.203l-1.359-3.325c-2.239-5.48-8.496-8.105-13.974-5.868c-5.48,2.239-8.106,8.495-5.869,13.975l1.359,3.325
            c1.696,4.153,5.7,6.667,9.925,6.667c1.35,0,2.723-0.257,4.049-0.799C382.777,145.941,385.404,139.684,383.166,134.203z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M350.286,28.013c-5.919,0-10.717,4.798-10.717,10.717v2.679c0,5.918,4.798,10.717,10.717,10.717
            c5.919,0,10.717-4.799,10.717-10.717V38.73C361.003,32.811,356.205,28.013,350.286,28.013z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M460.209,34.19c-7.894-6.971-18.879-12.547-32.649-16.572c-0.15-0.044-0.301-0.085-0.452-0.122l-22.581-5.541
            c-0.025-0.005-0.05-0.008-0.076-0.014c-0.031-0.008-0.059-0.019-0.09-0.026l-36.892-8.431c-24.066-8.696-51.14-0.73-66.078,19.548
            c-0.407,0.556-0.803,1.111-1.245,1.748c-11.472,16.78-13.922,38.105-6.553,57.045c0.03,0.076,0.061,0.153,0.092,0.228
            l62.439,150.202c2.598,6.78,1.708,14.078-2.455,20.055c-4.016,5.782-10.85,9.233-18.28,9.233h-7.064
            c-2.316-17.909-10.424-34.466-23.403-47.445c-15.535-15.535-36.194-24.091-58.173-24.091h-35.184
            c-50.018,0-94.721,30.235-113.602,75.983H82.641c-25.694,0-46.597,20.909-46.597,46.608v0.354c0,5.918,4.798,10.717,10.717,10.717
            h52.61h39.921h31.347h21.459c2.906,10.888,10.131,20.026,19.719,25.47l-57.694,57.693c-3.065,3.065-3.982,7.674-2.323,11.679
            c1.659,4.005,5.566,6.616,9.901,6.616h59.865v76.155c0,5.919,4.798,10.717,10.717,10.717c5.919,0,10.717-4.798,10.717-10.717
            v-76.155h40.336l20.953,20.953c2.093,2.093,4.836,3.14,7.578,3.139c2.742,0,5.486-1.047,7.578-3.139
            c4.185-4.185,4.185-10.97,0-15.156l-24.092-24.092c-2.009-2.009-4.736-3.139-7.578-3.139h-44.775v-50.554
            c14.339-3.826,25.651-15.133,29.479-29.469h63.501c27.638,0,53.461-13.622,69.076-36.443c15.606-22.82,18.941-51.825,8.923-77.59
            c-0.023-0.057-0.045-0.113-0.068-0.168l-21.115-51.693c-2.238-5.48-8.494-8.106-13.974-5.869
            c-5.48,2.238-8.106,8.494-5.869,13.974l21.079,51.607c7.415,19.147,4.923,40.688-6.668,57.639
            c-11.616,16.976-30.825,27.111-51.385,27.111h-51.33c5.772-8.112,9.437-18.075,9.437-29.107c0-13.112-5.317-25.771-14.977-35.652
            c-9.259-9.456-21.16-14.879-32.649-14.879h-6.098c-39.017,0-69.734,3.868-93.025,11.754c17.716-14.471,40.229-22.91,64.228-22.91
            h35.184c16.253,0,31.529,6.326,43.017,17.813c11.487,11.486,17.813,26.759,17.813,43.006c0,5.919,4.798,10.717,10.717,10.717
            h17.094c14.449,0,27.864-6.893,35.876-18.427c8.326-11.953,10.073-26.593,4.796-40.167c-0.03-0.076-0.061-0.153-0.092-0.229
            L313.527,73.95c-4.752-12.317-3.142-26.167,4.265-37c0.29-0.419,0.581-0.827,0.869-1.22c9.408-12.772,26.668-17.674,41.977-11.918
            c0.451,0.169,0.914,0.309,1.385,0.417l29.234,6.68V57.87h-31.39c-6.978,0-13.246,3.302-17.187,9.045
            c-3.974,5.772-4.789,12.834-2.239,19.37c0.02,0.053,0.042,0.106,0.063,0.158l9.932,24.315c1.697,4.153,5.7,6.667,9.925,6.667
            c1.35,0,2.722-0.257,4.049-0.799c5.479-2.238,8.106-8.494,5.869-13.974l-9.537-23.349h41.234h17.608
            c1.241,0,2.251,1.01,2.251,2.252v13.15c0,5.919,4.798,10.717,10.717,10.717h7.052c20.045,0,36.352-16.307,36.352-36.352v-0.482
            C475.954,54.989,470.657,43.417,460.209,34.19z M89.133,302.238H59.709c3.957-8.726,12.747-14.811,22.932-14.811h8.676
            C90.286,292.313,89.557,297.26,89.133,302.238z M221.564,403.697h-33.992l33.992-33.992V403.697z M232.282,333.114
            c-7.175,0-13.47-3.78-17.043-9.442h31.222h2.873C245.758,329.335,239.461,333.114,232.282,333.114z M240.362,244.034h6.098
            c5.679,0,12.159,3.155,17.328,8.435c5.715,5.846,8.864,13.184,8.864,20.661c0,15.676-14.401,29.107-26.192,29.107h-65.106v-14.093
            c0-5.918-4.798-10.717-10.717-10.717c-5.919,0-10.717,4.799-10.717,10.717v14.093h-9.913v-14.093
            c0-5.918-4.798-10.717-10.717-10.717c-5.919,0-10.717,4.799-10.717,10.717v14.093H110.68c2.117-17.991,9.997-30.205,25.32-39.389
            C156.827,250.365,191.939,244.034,240.362,244.034z M454.52,69.069c0,6.962-4.794,12.825-11.253,14.463v-1.977
            c0-13.061-10.625-23.686-23.685-23.686h-6.891V36.03l9.077,2.228c10.616,3.125,18.775,7.162,24.253,11.999
            c5.798,5.121,8.499,10.945,8.499,18.33V69.069z"/>
        </g>
      </g>
      </svg>
  )
}

export const Gull = ({ color, size }) => {
  return (
    <svg fill={color} height={size} width={size} version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512"  xmlSpace="preserve">
      <g>
        <path className="st0" d="M133.816,321.993c-5.99,0-10.848,4.863-10.848,10.857c0,5.985,4.859,10.848,10.848,10.848
          c5.99,0,10.849-4.863,10.849-10.848C144.666,326.855,139.807,321.993,133.816,321.993z"/>
        <path className="st0" d="M43.114,187.668l52.436,10.618l0.088,0.015c7.022,1.185,12.896,6.001,15.435,12.649l0.142,0.37l28.226,55.013
          c4.94-1.139,10.018-1.793,15.208-2.047l-30.381-59.214h-0.007c-4.49-10.987-14.28-18.92-25.988-20.982l0.008-0.008l-15.492-3.139
          v-38.086l49.031-0.816c28.168,0.015,53.94,15.85,66.67,40.979l0.143,0.277l33.062,53.321l6.355-17.196l-26.922-43.419
          c-15.284-29.715-45.892-48.427-79.346-48.427l-105.163,1.755v0.016c-6.686,0.046-12.972,2.454-17.912,6.494
          c-2.481,2.046-4.628,4.539-6.19,7.479c-1.566,2.924-2.52,6.317-2.516,9.902c-0.008,3.532,0.942,7.186,2.796,10.565
          c2.797,5.093,7.464,9.586,14.012,13.526c6.563,3.955,15.084,7.41,26.106,10.303L43.114,187.668z"/>
        <path className="st0" d="M499.17,309.621l8.672-72.279l-11.937,5.832c0,0-1.524,0.739-4.248,2.055
          c-9.522,4.593-33.573,16.111-56.74,26.436c-11.576,5.171-22.945,10.041-32.07,13.565c-4.559,1.77-8.56,3.194-11.66,4.14
          c-1.542,0.478-2.862,0.824-3.839,1.032c-0.492,0.107-0.897,0.176-1.178,0.215l-0.323,0.039h-0.031c-0.162,0-1.212-0.1-2.538-0.277
          c-4.987-0.638-14.781-2.177-23.352-3.547c-3.847-0.615-7.417-1.185-10.295-1.646l14.273-71.364l0.011-0.054
          c1.62-7.802,7.702-13.919,15.5-15.573l80.65-17.166l-0.046,0.016c16.981-3.516,29.446-8.872,38.093-15.426
          c4.321-3.278,7.687-6.887,10.042-10.803c2.35-3.901,3.662-8.179,3.658-12.449c0.008-3.67-0.981-7.272-2.789-10.395
          c-2.712-4.717-7.094-8.302-12.395-10.68c-5.324-2.378-11.63-3.608-18.72-3.616H348.103c-37.594,0-71.152,23.559-83.932,58.915
          l-35.94,97.192c-26.426,0-51.139,0-69.097,0c-17.174-0.031-31.581,5.378-43.203,13.295c-17.47,11.896-29.053,29.061-37.913,42.756
          c-4.405,6.84-8.156,12.865-11.214,16.735c-1.512,1.939-2.855,3.292-3.698,3.924l-0.696,0.439h-1.847l-1.786,1.146
          c-0.084,0.054-5.901,3.793-11.892,8.325c-3.012,2.278-6.043,4.717-8.582,7.202c-1.269,1.262-2.423,2.516-3.455,3.986
          c-0.519,0.746-1.012,1.554-1.446,2.562c-0.419,1.008-0.823,2.255-0.831,3.916c-0.019,1.408,0.381,3.147,1.312,4.601
          c0.685,1.093,1.592,1.97,2.485,2.57c1.358,0.916,2.624,1.308,3.758,1.547c1.146,0.231,2.201,0.299,3.278,0.307
          c13.754,0,75.422,0,81.454,0c5.525,0.785,53.382,7.233,135.533,7.233c78.157,0.015,118.428-18.643,127.238-23.306H512
          L499.17,309.621z M383.754,356.555l-1.723,1.039c-0.516,0.284-37.859,22.252-121.637,22.267c-43.195,0-76.761-1.801-99.486-3.594
          c-11.364-0.9-20.016-1.792-25.81-2.462c-2.896-0.339-5.074-0.616-6.524-0.808c-0.723-0.093-1.262-0.17-1.616-0.224l-0.504-0.068
          l-0.523-0.077h-0.55c0,0-46.615,0-70.671,0c2.482-1.916,5.078-3.77,7.21-5.24c1.377-0.954,2.565-1.746,3.396-2.293l0.116-0.076
          c1.384-0.3,2.696-0.731,3.828-1.332c3.486-1.9,5.952-4.547,8.429-7.563c4.252-5.294,8.36-12.096,13.165-19.505
          c7.167-11.088,15.777-23.391,26.776-32.678c5.497-4.655,11.56-8.556,18.373-11.31c6.825-2.754,14.399-4.386,23.132-4.386
          c16.742,0,39.348,0,63.746,0l-3.828,10.349l13.573,5.016l45.172-122.16l-0.02,0.046c10.699-29.615,38.825-49.351,70.325-49.351
          h75.045v41.949l-46.746,9.948c-13.411,2.854-23.864,13.358-26.652,26.784l-20.502,102.517l14.18,2.839l3.362-16.796
          c5.009,0.785,12.626,2.016,19.774,3.139c4.312,0.67,8.448,1.301,11.753,1.778c1.654,0.231,3.1,0.423,4.289,0.57
          c0.6,0.07,1.131,0.123,1.627,0.17c0.504,0.046,0.916,0.076,1.536,0.084c1.88-0.015,3.47-0.315,5.289-0.731
          c3.375-0.793,7.39-2.101,12.1-3.824c16.396-6.002,41.014-17.051,61.723-26.66c9.864-4.578,18.782-8.81,25.444-11.996l-3.301,27.506
          l-47.13,13.473l2.642,9.272l43.238-12.357l-1.25,10.449l3.074,14.711l-49.212,4.278l0.838,9.61l50.362-4.386l4.613,22.082H383.754z
          "/>
      </g>
      </svg>
  )
}

export const Swan = ({ color, size }) => <svg width={size} height={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill={color} d="M372.67 44.934c-50.275.005-92.476 43.57-38.934 163.87 29.137 16.804 41.594 46.767 33.48 74.848-9.168 31.735-42.353 59.517-96.597 68.084-45.02 7.11-88.46 2.42-122.915-22.218-14.895-10.65-27.867-24.905-38.502-43.088-26.314-8.87-48.678-17.086-61.224-23.25-.197 10.192 1.916 21.786 6.594 33.963l-35.842-4.432L70.87 332.49 43.92 344.385l53.35 11.676c10.984 9.717 23.94 18.99 39.07 27.38l-.002.007c26.098 14.475 195.407 11.62 218.51.578 67.333-32.17 71.596-90.7 28.552-167.472-57.378-102.34-31.747-134.085 3.034-133.07.93 10.53 6.27 21.943 15.593 31.288 5.74 5.757 12.266 9.993 18.886 12.623 19.37 11.416 38.896 19.675 62.072 20.81 5.712.08 9.144-9.696 3.487-10.238-11.602-2.262-21.402-8.37-29.352-15.912 11.712-12.976 7.85-36.605-8.994-53.49-11.038-11.06-24.963-16.53-36.898-15.692-12.41-5.107-25.747-7.938-38.562-7.936zm56.598 37.433c6.16 0 10.95 4.793 10.95 10.953 0 6.157-4.79 10.95-10.95 10.95-6.16 0-10.954-4.793-10.954-10.95 0-6.156 4.795-10.953 10.954-10.953zm-208.653 118.95c-6.108.034-12.128.27-18.18.607-29.242 1.63-59.597 5.87-101.03 1.365 28.59 40.045 73.354 51.978 116.91 49.04-32.74 15.03-75.678 8.755-108.41-10.848.475 1.48.96 2.94 1.454 4.38 17.087 33.357 62.417 42.17 88.845 42.066-16.554 11.997-42.21 12.406-65.994 2.386 7.334 9.737 15.51 17.67 24.366 24.002 29.323 20.968 67.166 25.59 109.127 18.96 49.298-7.785 74.954-31.945 81.56-54.81 6.607-22.867-3.598-45.977-34.556-58.134-36.452-14.313-63.225-18.523-87.95-18.975-2.06-.037-4.106-.05-6.142-.04zM64.14 378.278c-29.424 5.99-46.933 13.47-46.933 21.588 0 19.668 102.56 35.612 229.072 35.612s229.072-15.944 229.072-35.612c0-8.098-17.424-15.562-46.72-21.545 12.117 3.613 18.923 7.643 18.923 11.895 0 15.515-90.098 28.092-201.24 28.092-111.14 0-201.24-12.577-201.24-28.092 0-4.27 6.858-8.314 19.064-11.938z"/></svg>

export const Robin = ({ color, size }) => {
  return (
    <svg fill={color} height={size} width={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 470 470" xmlSpace="preserve">
      <g>
        <path d="M468.274,395.843L201.373,73.765c-15.61-18.84-38.584-29.646-63.03-29.646c-42.437,0-77.521,32.587-81.453,74.052H37.265
          c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h8.469C16.485,165.232,0,207.247,0,251.029
          C0,347.442,78.438,425.88,174.853,425.88c45.91,0,89.284-17.659,122.131-49.723c28.247-27.575,46.04-63.173,51.175-101.754
          l108.567,131.01c2.641,3.189,7.37,3.632,10.56,0.989S470.918,399.032,468.274,395.843z M66.902,133.171h51.441
          c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5H71.988c0.78-6.686,2.551-13.073,5.165-19.014h41.19
          c14.77,0,26.786,12.017,26.786,26.787c0,5.865-1.861,11.435-5.384,16.106c-2.246,2.979-1.961,7.155,0.668,9.802
          c23.522,23.671,36.834,54.811,37.705,88.02L47.777,347.885C27.227,320.989,15,287.413,15,251.029
          C15,206.219,33.89,163.431,66.902,133.171z M174.853,410.88c-46.377,0-88.198-19.859-117.425-51.513l133.022-110.233
          c1.72-1.425,2.715-3.542,2.715-5.775c0-36.488-13.433-70.957-37.953-97.724c3.232-6.021,4.918-12.724,4.918-19.691
          c0-23.041-18.745-41.787-41.786-41.787H86.277c12.266-15.247,31.056-25.038,52.066-25.038c19.965,0,38.729,8.826,51.48,24.216
          l144.732,174.652C330.913,343.946,261.29,410.88,174.853,410.88z"/>
        <path d="M361.073,326.344c-2.642-3.189-7.37-3.633-10.56-0.989c-3.189,2.643-3.633,7.371-0.989,10.561l57.593,69.499
          c1.482,1.79,3.622,2.714,5.778,2.714c1.686,0,3.382-0.565,4.781-1.725c3.189-2.643,3.633-7.371,0.989-10.561L361.073,326.344z"/>
      </g>
      </svg>
  )
}
